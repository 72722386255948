<template>
  <div id="base-plan" class="mb-10">
    <div>
      <b-card no-body>
        <b-card-text>
          <div class="row">
            <div class="col-md-12" style="background: white">
              <h5 class="p-2 mt-2">Packing materials report</h5>
              <hr />
              <div class="row mt-4">
                <div v-if="isEdit" class="col-md-3">
                  <div class="form-group">
                    <label for="">Material</label>
                    <select
                      :disabled="true"
                      class="form-control"
                      :class="[
                        errors['material'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="material"
                      @change="SelectMaterial()"
                    >
                      <option value="">Select Material</option>
                      <option
                        v-for="(item, index) in materialList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else class="col-md-3">
                  <div class="form-group">
                    <label for="">Material</label>
                    <select
                      class="form-control"
                      :class="[
                        errors['material'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="material"
                      @change="SelectMaterial()"
                    >
                      <option value="">Select Material</option>
                      <option
                        v-for="(item, index) in materialList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Sampling Date</label>
                    <date-picker
                      :date-format="'YYYY-MM-DD'"
                      :shortcuts="dateoptions"
                      valueType="format"
                      v-model="sampling_date"
                      :class="[
                        errors['sampling_date'] ? 'border border-danger' : '',
                      ]"
                    ></date-picker>
                  </div>
                </div>
                <div v-if="isEdit" class="col-md-3">
                  <div class="form-group">
                    <label for="">Supplier</label>
                    <select
                      :disabled="true"
                      class="form-control"
                      :class="[
                        errors['suppliers'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="suppliers"
                      @change="SelectSupplier()"
                    >
                      <option value="">Select Supplier</option>
                      <option
                        v-for="(item, index) in supplierList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else class="col-md-3">
                  <div class="form-group">
                    <label for="">Supplier</label>
                    <select
                      class="form-control"
                      :class="[
                        errors['suppliers'] ? 'border border-danger' : '',
                      ]"
                      id="exampleFormControlSelect1"
                      v-model="suppliers"
                      @change="SelectSupplier()"
                    >
                      <option value="">Select Supplier</option>
                      <option
                        v-for="(item, index) in supplierList"
                        :key="index"
                        :value="item.id"
                      >
                        {{ item.name }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Bill No.</label>
                    <input
                      type="text"
                      v-model="bill_no"
                      class="form-control"
                      :class="[errors['bill_no'] ? 'border border-danger' : '']"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Total Received in Kg.</label>
                    <input
                      type="text"
                      v-model="total_received"
                      class="form-control"
                      :class="[
                        errors['total_received'] ? 'border border-danger' : '',
                      ]"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Size</label>
                    <input
                      type="text"
                      v-model="size"
                      class="form-control"
                      :class="[errors['size'] ? 'border border-danger' : '']"
                    />
                    <!-- <input
                          type="text"
                          class="form-control"
                          v-model="shift_incharge"
                          :class="[
                            errors['shift_incharge'] ? 'border border-danger' : '',
                          ]"
                        /> -->
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Brand</label>
                    <input
                      type="text"
                      v-model="brand"
                      class="form-control"
                      :class="[errors['brand'] ? 'border border-danger' : '']"
                    />
                  </div>
                </div>
                <div class="col-md-3">
                  <div class="form-group">
                    <label for="">Reported Date</label>
                    <date-picker
                      :date-format="'YYYY-MM-DD'"
                      :shortcuts="dateoptions"
                      valueType="format"
                      v-model="reported_date"
                      :class="[
                        errors['reported_date'] ? 'border border-danger' : '',
                      ]"
                    ></date-picker>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12" style="background: white">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th scope="col">S No.</th>
                    <th style="width: 20%">Parameter</th>
                    <th scope="col">Specification</th>
                    <th scope="col">Observation</th>
                    <th scope="col">Remarks</th>
                  </tr>
                </thead>

                <tbody>
                  <tr
                    v-for="(item, index) in details"
                    :key="index"
                    :class="[item.error == 1 ? 'row_red' : '']"
                  >
                    <td>{{ index + 1 }}</td>
                    <td class="ino">
                      {{ item.parameter }}
                    </td>
                    <td class="inno">
                      {{ item.specification }}
                    </td>
                    <td class="inno">
                      <input
                        type="number"
                        :id="'detail_observation_' + index"
                        v-model="item.observation"
                        class="form-control"
                      />
                    </td>
                    <td class="inno">
                      <input
                        type="text"
                        :id="'detail_remarks_' + index"
                        v-model="item.remarks"
                        class="form-control"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="btn btn-smplan-fixed">
            <button
              ref="kt_submit_base_plan"
              class="btn btn-warning mt-2 mb-2 mr-5"
              @click="goback()"
            >
              Go back
            </button>
            <button
              v-if="!isEdit"
              ref="kt_submit_base_plan"
              vi
              class="btn btn-success mt-2 mb-2 mr-3"
              @click="save()"
            >
              Save
            </button>
            <button
              v-if="isEdit"
              ref="kt_submit_base_plan"
              vi
              class="btn btn-success mt-2 mb-2 mr-3"
              @click="update()"
            >
              Update
            </button>
          </div>
        </b-card-text>
      </b-card>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import {
  STORE_PACKING_MATERIAL_REPORT,
  FETCH_ALL_MATERIAL_LIST,
  FETCH_SELF_PACKING_MATERIAL_REPORT,
  UPDATE_PACKING_MATERIAL_REPORT,
} from "@/core/services/store/actions.type";
// import moment from "moment";

import { SET_BREADCRUMB } from "@/core/services/store/mutations.type";
export default {
  computed: {
    ...mapGetters(["currentUser"]),
  },
  components: {
    DatePicker,
  },
  data() {
    return {
      isEdit: false,
      dateoptions: [{ text: "Today", onClick: () => new Date() }],
      details: [],
      id: 0,
      materialList: [],
      supplierList: [],
      temp_start_time: "",
      temp_end_time: "",
      old_temp_start_time: 0,
      results: [],
      errors: {},
      breadCrumbs: [
        {
          id: 1,
          title: "Dashboard",
          route: "client.dashboard",
        },
        {
          id: 2,
          title: "Create Packing Material Analysis Report",
          route: "",
        },
      ],
      material: "",
      suppliers: "",
      material_name: "",
      sampling_date: "",
      supplier: "",
      bill_no: "",
      total_received: "",
      size: "",
      reported_date: this.$moment(new Date()).format("YYYY-MM-DD"),
      brand: "",
      add_status: 1,
      error_status: 1,
    };
  },
  created() {
    this.isEdit =
      this.$route.name == "client.qualitycontrol.packingmaterials.edit"
        ? true
        : false;
    this.$store.dispatch(FETCH_ALL_MATERIAL_LIST).then((data) => {
      if (this.isEdit) {
        this.supplierList = data.filter((item) => {
          return (
            item.is_supplier == true &&
            item.material_type_name == "Packing Material"
          );
        });
        this.materialList = data.filter((item) => {
          // console.log(item.name);

          return (
            item.is_supplier == false &&
            item.material_type_name == "Packing Material"
          );
        });
      } else {
        this.supplierList = data.filter((item) => {
          return (
            item.is_supplier == true &&
            item.material_type_name == "Packing Material" &&
            item.is_deleted == false
          );
        });
        this.materialList = data.filter((item) => {
          // console.log(item.name);

          return (
            item.is_supplier == false &&
            item.material_type_name == "Packing Material" &&
            item.is_deleted == false
          );
        });
      }
    });

    var id = this.$route.params.id;

    if (this.isEdit) {
      this.breadCrumbs[1].title = "Update Packing Material Report";
      this.$store
        .dispatch(FETCH_SELF_PACKING_MATERIAL_REPORT, id)
        .then((data) => {
          this.details = data.data.over_report_detail;
          this.material_name = data.data.material_name;
          this.sampling_date = data.data.sampling_date;
          this.bill_no = data.data.bill_no;
          this.total_received = data.data.total_received;
          this.size = data.data.size;
          this.brand = data.data.brand;
          this.reported_date = data.data.reported_date;
          this.supplier = data.data.supplier;
          this.suppliers = data.data.suppliers ? data.data.suppliers : "";
          this.material = data.data.material ? data.data.material : "";
        })
        .catch(() => {
          this.$toastr.e("detail not found!");
          this.$router.push({ name: "client.qualitycontrol.packingmaterials" });
        });
    }
    this.$store.commit(SET_BREADCRUMB, this.breadCrumbs);
  },
  methods: {
    save() {
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.suppliers == "") {
        this.errors["suppliers"] = "This field is required";
        err = 1;
      }
      if (this.material == "") {
        this.errors["material"] = "This field is required";
        err = 1;
      }

      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        packing_material_detail: this.details,
        material_name: this.material_name,
        sampling_date: this.sampling_date,
        bill_no: this.bill_no,
        total_received: this.total_received,
        supplier: this.supplier,
        size: this.size,
        reported_date: this.reported_date,
        brand: this.brand,
        suppliers: this.suppliers,
        material: this.material,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(STORE_PACKING_MATERIAL_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.qualitycontrol.packingmaterials",
          });
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    SelectMaterial() {
      this.details = [];
      if (this.material != "") {
        var data = this.materialList.filter((item) => {
          // console.log(item.name);
          return item.id == this.material;
        });
        this.material_name = data[0].name;
        data[0].details.map((item) => {
          var data_ = {
            parameter: item.parameter,
            specification: item.specification,
            observation: "",
            remarks: "",
          };
          this.details.push(data_);
        });
      } else {
        this.material_name = "";
        this.details = [];
      }
    },
    SelectSupplier() {
      if (this.suppliers != "") {
        var data = this.supplierList.filter((item) => {
          // console.log(item.name);
          return item.id == this.suppliers;
        });
        this.supplier = data[0].name;
      } else {
        this.supplier = "";
      }
    },
    update() {
      let msg = "Please fill all the value";
      var err = 0;
      this.errors = {};
      if (this.supplier == "") {
        this.errors["suppliers"] = "This field is required";
        err = 1;
      }
      if (this.material == "") {
        this.errors["material"] = "This field is required";
        err = 1;
      }

      if (err == 1) {
        this.$toastr.w(msg);
        return 0;
      }
      var formData = {
        id: this.$route.params.id,
        packing_material_detail: this.details,

        material_name: this.material_name,
        sampling_date: this.sampling_date,
        bill_no: this.bill_no,
        total_received: this.total_received,
        supplier: this.supplier,
        size: this.size,
        reported_date: this.reported_date,
        brand: this.brand,
        suppliers: this.suppliers,
        material: this.material,
      };

      const submitButton = this.$refs["kt_submit_base_plan"];
      submitButton.disabled = true;
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      this.$store
        .dispatch(UPDATE_PACKING_MATERIAL_REPORT, formData)
        .then((res) => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.s(res.msg);
          this.$router.push({
            name: "client.qualitycontrol.packingmaterials",
          });
          //   if (this.$route.params.client == "sp") {
          //     this.getLocationwisebasePlan();
          //   } else {
          //     this.fetchbasePlan();
          //   }
        })
        .catch(() => {
          submitButton.classList.remove(
            "spinner",
            "spinner-light",
            "spinner-right"
          );
          submitButton.disabled = false;
          this.$toastr.e("Something went wrong !");
        });
    },
    removeplan(index) {
      // console.log(weekday, index);
      this.details.splice(index, 1);
    },

    reset() {
      this.material_name = "";
      this.sampling_date = "";
      this.supplier = "";
      this.bill_no = "";
      this.total_received = "";
      this.size = "";
      this.reported_date = "";
      this.brand = "";
      this.add_status = 1;
      this.error_status = 1;
      this.details = [];
      this.material = "";
      this.suppliers = "";
    },
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.42rem;
}
.mx-datepicker .mx-input {
  height: 2.8rem;
}
.row_red {
  background-color: #f89494 !important;
}
.base-plan {
  .btn-plan-fixed {
    position: fixed;
    right: 3%;
    top: 89%;
    width: 75%;
    background-color: black;
  }
}
.btn-smplan-fixed {
  position: fixed;
  top: 93%;
  background-color: #ffff;
  width: calc(71vw - 0%);

  button {
    float: right;
  }
}
table {
  .baseplan-header {
    background: #b4cfe2;
  }
}
</style>
<style>
.mx-input-wrapper {
  border: 1px solid #e4e6ef;
  border-radius: 0.45rem;
}
.mx-input {
  height: 3rem;
}
.swal2-popup .swal2-icon {
  margin-left: 39% !important;
}
.icon-green {
  color: green;
}
.icon-red {
  color: red;
}
</style>

<style scoped>
input[type="number"] {
  -moz-appearance: textfield !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.action {
  width: 20%;
}
.datetime {
  width: 10%;
}
.ino {
  width: 10%;
}
.form-inline-flex {
  display: inline-flex;
}
.label1 {
  margin-right: 2%;
  margin-top: 2%;
}
.schedule-input {
  width: 27%;
  margin-right: 2%;
}
.label2 {
  margin-top: 2%;
}
</style>
